<template>
  <div class="container" @click="closeAll">
    <div class="shigeIcon">
      <img src="../assets/image/jht-icon-30.png" alt=""/>
    </div>
    <div class="userBox">
      <div class="userName" @click.stop="tabToggle">
        <div class="name">您好！莫干山管理局</div>
        <div class="icon">
          <img src="../assets/image/jht-icon-08.png" alt=""/>
        </div>
        <div class="userTabBox" v-if="tabIsShow">
          <div class="list">莫干山管理局</div>
          <div class="list" @click="tuichu">退出</div>
        </div>
      </div>
      <div class="shezhi" @click="toshezhi(setUrl)">
        <div class="icon">
          <img src="../assets/image/jht-icon-09.png" alt=""/>
        </div>
        <div class="name">设置</div>
      </div>
      <div class="pifu" @click="qiehuan">
        <div class="icon">
          <img src="../assets/image/jht-icon-10.png" alt=""/>
        </div>
        <div class="name">皮肤</div>
      </div>
    </div>
    <div class="lieBox">
      <div
          class="list"
          v-for="(item, index) in list"
          :key="index"
          :class="'list' + index"
          @click.stop="open(index)"
      >
        <div class="img1" :class="curIndex != index ? 'img1Active' : ''">
          <img :src="item.icon" alt=""/>
        </div>
        <div class="img2" :class="curIndex == index ? 'img2Active' : ''">
          <img :src="item.icon1" alt=""/>
        </div>
        <div class="listBox" :class="curIndex == index ? 'listBoxActive' : ''">
          <div
              class="list_item"
              v-for="(e, v) in item.child"
              :key="v"
              @click.stop="toPage(e)"
          >
            <div
                class="winBox"
                v-if="
                (e.id == curId && e.id == '11601') ||
                (e.id == curId && e.id == '11602')
              "
            >
              <div class="erweima">
                <img :src="erweimaUrl" alt=""/>
              </div>
              <div class="close" @click.stop="curId = '-1'">
                <img src="../assets/image/jht-icon-35.png" alt=""/>
              </div>
            </div>
            <div class="uIcon">
              <img src="../assets/image/jht-icon-11.png" alt=""/>
            </div>
            <div class="name">{{ e.name }}</div>
            <div class="jiantou"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/server";
import md5 from "js-md5";
import mes from "@/components/message.vue";

export default {
  components: {
    mes,
  },
  name: "index",
  data() {
    return {
      list: [],
      curId: "-1",
      tabIsShow: false,
      curIndex: -1,
      setUrl: "",
      erweimaUrl: "",
    };
  },
  methods: {
    open(index) {
      this.curIndex = index;
      this.curId = "-1";
    },
    tabToggle() {
      this.tabIsShow = !this.tabIsShow;
    },
    toshezhi(url) {
      window.open(url);
    },
    toPage(e) {
      // if (e.moduleId == 'count022004') {
      //   // this.browselist()
      // }
      if (e.id) {
        this.curId = e.id;
        if (this.curId == "11601") {
          e.url = "";
          this.erweimaUrl = "https://digital.mgsfjq.com/" + e.iconActive;
        } else if (this.curId == "11602") {
          e.url = "";
          this.erweimaUrl = "https://digital.mgsfjq.com/" + e.iconActive;
        }
      }
      if (e.url) {
        window.open(e.url);
      }
    },
    // // 浏览量采集
    // browselist(){
    // 	let params = {
    // 		uid: Api.getUid(),
    // 		num_open: '1'
    // 	}
    // 	let data = {
    // 		custId: '1009',
    // 		systemId: 'admin',
    // 		visitUrl: '/collectLog/save',
    // 		accountId: '2304141055559936324',
    // 		appKey: 'nrm5c8av5kRQo4rfLuw5xSvpSaZrMUzM',
    // 		timeStamp: new Date().getTime(),
    // 		eventName: 'num_open',
    // 		data: JSON.stringify(params),
    // 		groupId: '01510001',
    // 		terminalId: '2304141027077991999'
    // 	}
    // 	data.token = md5(data.accountId + data.appKey + data.timeStamp)
    //
    // 	Api.ajaxRequest_admin("collectLog/save", data).then((res) => {
    // 	  let { code, data } = res;
    // 	  if (code == "200") {
    // 	    let list = data;
    //
    // 	  }else {
    //
    // 	  }
    // 	});
    //
    // },
    qiehuan() {
      this.$router.push("/index1");
    },
    tuichu() {
      this.$cookies.set("token", null);
      this.$cookies.set("uid", null);
      this.$router.push("/#/");
    },
    closeAll() {
      this.curIndex = -1;
      this.tabIsShow = false;
    },
    // 验证登录是否过期
    hasLogin() {
      // Api.ajaxRequest("auth/query/system/permission").then((res) => {
      //   let { code, data } = res;
      //   if (code == "200") {
      //     let list = data;
      //     let powerArr = [];
      //     list.forEach((item, index) => {
      //       powerArr.push(item.systemId);
      //     });
      //     this.powerArr = powerArr;
      //   }
      // });
      let params = {systemId: "count"};
      Api.ajaxRequest("auth/query/permission", params).then((res) => {
        let {code, data} = res;
        if (code == "200") {
          let list = data;
          list.forEach((item, index) => {
            if (item.moduleId == "count020") {
              item.icon = require("../assets/image/jht-icon-02.png");
              item.icon1 = require("../assets/image/jht-icon-32.png");
            } else if (item.moduleId == "count021") {
              item.icon = require("../assets/image/jht-icon-03.png");
              item.icon1 = require("../assets/image/jht-icon-33.png");
            } else if (item.moduleId == "count022") {
              item.icon = require("../assets/image/jht-icon-04.png");
              item.icon1 = require("../assets/image/jht-icon-31.png");
            }
            if (item.type == "菜单") {
              this.list.push(item);
            } else if (item.type == "按钮" && item.moduleId == "admin019") {
              this.setUrl = item.url;
            }
          });
        } else if (code == "503") {
          this.$message.error("登录信息已过期,请重新登录");
          setTimeout(() => {
            this.$router.push({path: "/"});
          }, 3000);
        } else {
          this.$message.error(res.description);
        }
      });
    },
  },
  mounted() {
    this.getToken = Api.getToken();
    this.hasLogin();
  },
};
</script>

<style scoped lang="less">
::-webkit-scrollbar {
  display: none;
}

.container {
  width: 100vw;
  height: 100vh;
  background-image: url(../assets/image/jht-icon-01.jpg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;

  .shigeIcon {
    width: 305px;
    height: 196px;
    position: absolute;
    left: 8%;
    bottom: 14%;
    animation: move1 5s ease-in infinite;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .userBox {
    display: flex;
    align-items: center;
    position: absolute;
    right: 25px;
    top: 32px;

    .userName {
      display: flex;
      align-items: center;
      cursor: pointer;
      position: relative;

      .name {
        color: #3d3d3d;
        font-size: 16px;
        margin-right: 5px;
      }

      .userTabBox {
        width: 100%;
        padding: 10px;
        background-color: #fff;
        position: absolute;
        left: 0;
        top: 30px;
        z-index: 1;

        .list {
          font-size: 13px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          color: #666;
        }

        .list:hover {
          font-weight: bold;
          color: #4f5b37 !important;
        }
      }
    }

    .shezhi {
      margin-left: 59px;
      display: flex;
      cursor: pointer;

      .icon {
        width: 24px;
        height: 24px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .name {
        font-size: 16px;
        color: #3d3d3d;
        margin-left: 9px;
      }
    }

    .pifu {
      display: flex;
      margin-left: 25px;
      cursor: pointer;

      .icon {
        width: 24px;
        height: 24px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .name {
        font-size: 16px;
        color: #3d3d3d;
        margin-left: 9px;
      }
    }
  }

  .lieBox {
    display: flex;
    width: 54.5%;
    height: 79%;
    justify-content: space-between;
    position: absolute;
    right: 1.3%;
    top: 12%;

    .list {
      width: 31.5%;
      height: 100%;
      cursor: pointer;
      overflow: hidden;
      position: relative;

      img {
        width: 100%;
        height: 100%;
      }

      .img1 {
        width: 142px;
        height: 284px;
        position: absolute;
        top: 0;
        right: 0px;
        bottom: 0;
        left: -480px;
        margin: auto;
        transition: all 1s;
      }

      .img2 {
        width: 291px;
        height: 132px;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        transition: all 1s;
        top: -150px;
      }

      .img1Active {
        left: 0;
        animation: move0 1s linear;
      }

      .img2Active {
        top: 109px;
        animation: move2 1s linear;
      }

      .listBox {
        width: 100%;
        height: 55%;
        position: absolute;
        left: 0;
        bottom: -100%;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        transition: all 1s;

        .list_item:hover .jiantou {
          display: block;
        }

        .list_item {
          display: flex;
          align-items: center;
          margin-bottom: 30px;
          padding-left: 32px;
          box-sizing: border-box;
          position: relative;

          .winBox {
            width: 230px;
            height: 230px;
            position: absolute;
            left: 30px;
            top: 40px;
            background-image: url(../assets/image/jht-icon-37.png);
            background-size: 100% 100%;
            z-index: 11;

            .close {
              width: 38px;
              height: 38px;
              position: absolute;
              bottom: -50px;
              left: 0;
              right: 0;
              margin: auto;
            }

            .erweima {
              width: 80%;
              height: 80%;
              position: absolute;
              bottom: 0;
              top: 0;
              left: 0;
              right: 0;
              margin: auto;

              img {
                width: 100%;
                height: 100%;
              }
            }
          }

          .uIcon {
            width: 14px;
            height: 10px;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .name {
            margin-left: 12px;
            color: #ffffff;
            font-size: 18px;
          }

          .jiantou {
            width: 19px;
            height: 4px;
            background-image: url(../assets/image/jht-icon-12.png);
            background-size: 100% 100%;
            position: absolute;
            right: 54px;
            top: 8px;
            display: none;
          }
        }
      }

      .listBoxActive {
        animation: move3 1s linear;
        bottom: 60px;
      }
    }

    .list0 {
      background-image: url(../assets/image/jht-icon-05.jpg);
      background-size: 100% 100%;
    }

    .list1 {
      background-image: url(../assets/image/jht-icon-06.jpg);
      background-size: 100% 100%;
    }

    .list2 {
      background-image: url(../assets/image/jht-icon-07.jpg);
      background-size: 100% 100%;
    }
  }
}

@keyframes move1 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes move0 {
  0% {
    left: -480px;
    opacity: 0;
  }

  100% {
    top: 0;
    opacity: 1;
  }
}

@keyframes move2 {
  0% {
    top: -150px;
    opacity: 0;
  }

  100% {
    top: 109px;
    opacity: 1;
  }
}

@keyframes move3 {
  0% {
    bottom: -350px;
    opacity: 0;
  }

  100% {
    bottom: 60px;
    opacity: 1;
  }
}
</style>
